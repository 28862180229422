import { useReducer } from "react"
import formStateReducer, {
    initialState as fieldsInitialState,
    types as fieldsTypes
} from "./formFieldsReducer"
import formControlReducer, {
    initialState as statusInitialState,
    types
} from "./formStatusReducer"
import axios from "axios"

function useFormControl({ config: { requiredFields = [], format = [] } }) {
    const [fields, updateFields] = useReducer(
        formStateReducer,
        fieldsInitialState
    )
    const [status, handleStatus] = useReducer(
        formControlReducer,
        statusInitialState
    )

    const handleChange = e => {
        if (status === types.SUBMITTING) {
            return
        }

        handleStatus({ type: types.IDLE })
        const { name, value } = e.target

        const formattedValue = formatField(name, value, format)
        updateFields({ type: name, payload: formattedValue })
    }

    const handleSubmit = async e => {
        e.preventDefault()
        if (status === types.SUBMITTING) {
            return
        }

        handleStatus({ type: types.SUBMITTING })

        try {
            await axios.post(
                `${process.env.GATSBY_EMAILER_SERVER}/api/contact-us`,
                fields
            )
            handleStatus({ type: types.SUCCESS })
        } catch {
            handleStatus({ type: types.ERROR })
        }
        //cleanup fields
        setTimeout(() => {
            updateFields({ type: fieldsTypes.RESET })
        }, 1000)
    }

    const isSubmitting = status === types.SUBMITTING

    const success = status === types.SUCCESS

    const error = status === types.ERROR

    const isSubmitted = success || error

    const readyToSubmit = checkFieldsStatus(fields, requiredFields)

    return {
        handleChange,
        handleSubmit,
        isSubmitted,
        fields,
        isSubmitting,
        success,
        error,
        readyToSubmit
    }
}

export default useFormControl

function checkFieldsStatus(currentState, requiredFields = []) {
    let countValidation = 0
    for (let i = 0; i < requiredFields.length; i++) {
        const fieldName = requiredFields[i].toLowerCase()
        if (currentState[fieldName]) {
            countValidation++
        }
    }
    return countValidation === requiredFields.length
}

function formatField(fieldName, fieldValue, rules = []) {
    for (let i = 0; i < rules.length; i++) {
        if (fieldName === rules[i].field) {
            return rules[i].formatter(fieldValue)
        }
    }
    return fieldValue
}
