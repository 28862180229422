import React, { useState, useRef, useEffect } from "react"
import styled from "@emotion/styled"
import { colors } from "../../styles/colors"
import { fontFamily } from "../../styles/fonts/fontFamily"
import { css } from "@emotion/core"
import Spacer from "../Partials/Spacer"
import { BodyText, AfterSubmissionHeader } from "../Partials/TextPieces"
import { maxW } from "../../styles/mediaQueries"

export const Form = styled.form`
    display: flex;
    flex-direction: column;
    padding: 0px 115px;
    input,
    textarea {
        background-color: ${colors.mischkaOpacity};
    }
    textarea {
        padding-top: 25px;
    }
    ${maxW[1]} {
        padding: 0px 0px;
    }
`

export const Field = ({
    type,
    name,
    onChange,
    value,
    label,
    required,
    height,
    textarea,
    pattern
}) => {
    const [visiting, setVisit] = useState(false)
    const inputField = useRef()

    const handleFocus = () => {
        setVisit(true)
    }

    const handleBlur = () => {
        if (!value) {
            setVisit(false)
        }
    }

    const handleClick = () => {
        inputField.current.focus()
    }

    const InputComponent = textarea ? TextArea : Input

    return (
        <FieldArea height={height}>
            <Label
                className={visiting ? "visiting" : "visited"}
                onClick={handleClick}
            >
                {label}
            </Label>
            <InputComponent
                ref={inputField}
                type={type}
                name={name}
                onChange={onChange}
                value={value}
                required={required}
                onFocus={handleFocus}
                onBlur={handleBlur}
                pattern={pattern}
            />
        </FieldArea>
    )
}

export const FieldsContainer = styled.div`
    padding: 20px 0 40px;
`

export const FormContainer = styled.div`
    position: relative;
    padding: 60px 0 30px;
`

export const AfterSubmissionMessage = ({ success, error }) => {
    const postSubmission = useRef()

    useEffect(() => {
        const { top: bodyTop } = document.body.getBoundingClientRect()
        const {
            top: elementTop
        } = postSubmission.current.getBoundingClientRect()
        window.scrollTo(0, elementTop - bodyTop)
    })

    return (
        <AfterSubmissionContainer ref={postSubmission}>
            <Spacer size={"medium"} />
            <ContentContainer>
                {success && <SuccessMessage />}
                {error && <ErrorMessage />}
            </ContentContainer>
            <Spacer size={"medium"} />
        </AfterSubmissionContainer>
    )
}

const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 40px;
    ${maxW[1]} {
        padding: 0 70px;
    }
`

const SuccessMessage = () => (
    <>
        <AfterSubmissionHeader>Thanks for contacting us!</AfterSubmissionHeader>
        <Spacer size="xsmall" />
        <BodyText copy="Someone in our team will be in contact as soon as posible" />
    </>
)

const ErrorMessage = () => (
    <>
        <AfterSubmissionHeader>
            Sorry, something went wrong
        </AfterSubmissionHeader>
        <Spacer size="xsmall" />
        <BodyText copy="Something went wrong and we couldn't get your message." />
        <BodyText copy="<p>Please, try again later or call us at <a href='tel:561-540-4909'>561-540-4909</a>.</p>" />
    </>
)

const AfterSubmissionContainer = styled.div`
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: ${colors.white};
    animation: fadeIn 0.5s ease-in forwards;

    @keyframes fadeIn {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
`

const Label = styled.label`
    font-family: ${fontFamily.accent};
    position: absolute;
    line-height: 35px;
    letter-spacing: 2.67px;
    cursor: text;

    &.visiting {
        animation: labelUp 0.3s forwards;
    }
    &.visited {
        animation: labelDown 0.3s forwards;
    }
    @keyframes labelUp {
        from {
            font-size: 14px;
            margin: 15px 0px 15px 20px;
            color: ${colors.eastBay};
        }
        to {
            font-size: 12px;
            margin: 2px 0 0 20px;
            color: ${colors.silver};
        }
    }
    @keyframes labelDown {
        from {
            font-size: 12px;
            margin: 2px 0 0 20px;
            color: ${colors.silver};
        }
        to {
            font-size: 14px;
            margin: 15px 0px 15px 20px;
            color: ${colors.eastBay};
        }
    }
`

const FieldArea = styled.div`
    margin: 20px;
    height: 65px;

    ${({ height }) =>
        css`
            height: ${height && `${height}px`};
        `};
`
const InputField = tag => styled(tag)`
    font-family: ${fontFamily.accent};
    width: calc(100% - 40px);
    height: calc(100% - 10px);
    line-height: 20px;
    font-size: 18px;
    padding: 10px 20px 0;
    letter-spacing: 1px;
    color: ${colors.black};
`

const Input = InputField("input")
const TextArea = InputField("textarea")
