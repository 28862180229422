const NAME = "NAME"
const EMAIL = "EMAIL"
const PHONE = "PHONE"
const COMMENTS = "COMMENTS"
const RESET = "RESET"

export const initialState = {
    name: "",
    email: "",
    phone: "",
    comments: ""
}

export default function formStateReducer(state = initialState, event) {
    switch (event.type) {
        case NAME:
            return { ...state, ...{ name: event.payload } }
        case EMAIL:
            return { ...state, ...{ email: event.payload } }
        case PHONE:
            return { ...state, ...{ phone: event.payload } }
        case COMMENTS:
            return { ...state, ...{ comments: event.payload } }
        case RESET:
            return { ...initialState }
        default:
            return state
    }
}

export const types = {
    NAME,
    EMAIL,
    PHONE,
    COMMENTS,
    RESET
}
