const SUCCESS = "SUCCESS"
const ERROR = "ERROR"
const SUBMITTING = "SUBMITTING"
const IDLE = "IDLE"

export const initialState = IDLE

export default function formControlReducer(state = initialState, event) {
    switch (event.type) {
        case SUCCESS:
            return SUCCESS
        case ERROR:
            return ERROR
        case SUBMITTING:
            return SUBMITTING
        case IDLE:
            return IDLE
        default:
            return state
    }
}

export const types = {
    SUCCESS,
    ERROR,
    SUBMITTING,
    IDLE
}
