import React from "react"
import { types } from "./formFieldsReducer"
import useFormControl from "./useFormControl"
import { Button, MaxWidthInnerContainer } from "../../styles/globalCss"
import {
    Form,
    Field,
    FieldsContainer,
    FormContainer,
    AfterSubmissionMessage
} from "./FormsComponents"
import BackgroundImage from "../Partials/BackgroundImage"
import { FormInstructions, HeaderText } from "../Partials/TextPieces"
import Spacer from "../Partials/Spacer"
import { formatPhoneNumber } from "../utils/functions"
import styled from "@emotion/styled"

import { css } from "@emotion/core"

const { NAME, EMAIL, PHONE, COMMENTS } = types

const ContactUsForm = ({ bkgImg, description }) => {
    const {
        handleSubmit,
        handleChange,
        isSubmitted,
        fields,
        isSubmitting,
        success,
        error,
        readyToSubmit
    } = useFormControl({
        config: {
            requiredFields: [NAME, EMAIL, PHONE],
            format: [{ field: PHONE, formatter: formatPhoneNumber }]
        }
    })

    return (
        <FormContainer>
            <BackgroundImage
                bkgImg={bkgImg}
                partial
                description={description}
            />
            <MaxWidthInnerContainer
                transparent
                css={css`
                    @media only screen and (max-width: 600px) {
                        .header-text-container {
                            position: relative;
                            top: -40px;
                        }
                    }
                `}
            >
                <HeaderText copy="Membership Inquiries" smallH2 />
            </MaxWidthInnerContainer>
            <MaxWidthInnerContainer>
                <FormContainer>
                    <Form onSubmit={handleSubmit}>
                        <FormInstructions>
                            For membership inquiries, please fill out the form
                            below. Upon receipt of your request the membership
                            office will be in contact to arrange a meeting to
                            discuss potential membership opportunities
                        </FormInstructions>
                        <FieldsContainer>
                            <Field
                                type="text"
                                name={NAME}
                                onChange={handleChange}
                                value={fields.name}
                                label="FIRST / LAST NAME"
                                required
                            />
                            <Field
                                type="tel"
                                name={PHONE}
                                onChange={handleChange}
                                value={fields.phone}
                                label="PHONE NUMBER"
                                required
                                pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                            />
                            <Field
                                type="email"
                                name={EMAIL}
                                onChange={handleChange}
                                value={fields.email}
                                label="EMAIL ADDRESS"
                                required
                            />
                            <Field
                                type="text"
                                name={COMMENTS}
                                onChange={handleChange}
                                value={fields.comments}
                                label="COMMENTS (OPTIONAL)"
                                height={140}
                                textarea
                            />
                        </FieldsContainer>
                        <ButtonForm
                            type="submit"
                            className={readyToSubmit ? undefined : "not-ready"}
                            disabled={isSubmitting}
                        >
                            {isSubmitting ? "SUBMITTING" : "SUBMIT"}
                        </ButtonForm>
                        <Spacer size="xxsmall" />
                    </Form>
                    {isSubmitted && (
                        <AfterSubmissionMessage
                            success={success}
                            error={error}
                        />
                    )}
                </FormContainer>
            </MaxWidthInnerContainer>
        </FormContainer>
    )
}

export default ContactUsForm

const ButtonForm = styled(Button)`
    align-self: center;
`
