export const formatPhoneNumber = phoneNumber => {
    let finalPhoneNumber = ""
    for (let i = 0; i < phoneNumber.length; i++) {
        const numberRegex = /^[0-9]*$/
        const isNumber = numberRegex.test(phoneNumber[i])
        if (!isNumber) continue
        if (finalPhoneNumber.length === 3 || finalPhoneNumber.length === 7) {
            finalPhoneNumber += "-"
        }
        finalPhoneNumber += phoneNumber[i]
    }

    return finalPhoneNumber.slice(0, 12)
}
